import axios from "axios";
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { IoEllipsisVertical } from "react-icons/io5";
import { GrConfigure, GrStatusWarning } from "react-icons/gr";
import { MdPageview, MdCheckCircle, MdAttachEmail, MdOutlineModeEdit, MdEditDocument } from "react-icons/md";
import StatusModal from './StatusModal';
import TransportDetailsModal from './TransportDetailsModal';
import SendDocumentModal from './SendDocumentModal';
import { IoMdAdd } from "react-icons/io";
import { FiCheck } from "react-icons/fi";
import { IoMdPhotos } from "react-icons/io";
import { GrFormView } from "react-icons/gr";
import { IoTicket } from "react-icons/io5";
import { FaRegFilePdf, FaMapMarkerAlt, FaTruck, FaDollarSign, FaSearch, FaCheck, FaHistory, FaArrowAltCircleUp, FaArrowAltCircleDown, FaFileInvoice } from 'react-icons/fa';
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaFilePdf, FaDoorOpen } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import AddPhotosModal from './AddPhotosModal';
import GatePassModal from './GatePassModal';
import ConfirmationModal from './ConfirmationModal';
import PdfSelectionModal from './PdfSelectionModal';
import OthersModal from './OthersModal';
import ReleaseModal from './ReleaseModal';
import PdfGatePassModal from './PdfGatePassModal';
import OptionsModal from './OptionsModal';
import '../components/css/Load.css';
import fallbackImage from '../assets/img/img-profile-error.png';

const shouldShowPickupButton = (loadTransport, loadItem) => {
  const showButton = (
    (loadTransport.load_status.load_status_name === "Confirmed") &&
    loadItem.inspection_load_items.length === 0 &&
    loadItem.pickup_bol_path === null
  );
  return showButton;
};

const shouldShowDeliveryButton = (loadTransport, loadItem) => {
  const showButton = (
    loadTransport.load_status.load_status_name === "Picked Up" &&
    loadItem.inspection_load_items[0]?.inspection_type === "Pickup" &&
    loadItem.dropoff_bol_path === null
  );
  return showButton;
};

// Local status cache
const statusCache = {};

const Card = ({ transport }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedTransport, setSelectedTransport] = useState(null);
  const dropdownRef = useRef(null);
  const [currentStatus, setCurrentStatus] = useState(transport.load_status.load_status_name);
  const [pickupInspectionDone, setPickupInspectionDone] = useState(false);
  const [deliveryInspectionDone, setDeliveryInspectionDone] = useState(false);
  const history = useHistory();
  const [isSendDocumentModalOpen, setIsSendDocumentModalOpen] = useState(false);
  const [selectedTransportId, setSelectedTransportId] = useState(null);
  const [isAddPhotosModalOpen, setIsAddPhotosModalOpen] = useState(false);
  const [isGatePassModalOpen, setIsGatePassModalOpen] = useState(false);
  const [selectedLoadItemId, setSelectedLoadItemId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isPickupButtonDisabled, setIsPickupButtonDisabled] = useState(false);
  const [isDeliveryButtonDisabled, setIsDeliveryButtonDisabled] = useState(false);
  const [gatePassUrl, setGatePassUrl] = useState('');
  const [deleted, setDeleted] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [loadToDelete, setLoadToDelete] = useState(null);
  const [isPdfSelectionModalOpen, setIsPdfSelectionModalOpen] = useState(false);
  const [isOthersModalOpen, setIsOthersModalOpen] = useState(false);
  const [isReleaseModalOpen, setIsReleaseModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [isPdfGatePassModalOpen, setIsPdfGatePassModalOpen] = useState(false);
  const [selectedLoadTransportId, setSelectedLoadTransportId] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef(null);
  const [isCurrentStatusPopupOpen, setIsCurrentStatusPopupOpen] = useState(false);
  const currentStatusPopupRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  // Função para alternar a visibilidade de um item específico
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };




  const openConfirmationModal = (loadId) => {
    setLoadToDelete(loadId);
    setDropdownOpen(false);
    setIsConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setLoadToDelete(null);
    setIsConfirmationModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (loadToDelete) {
      await handleDeleteLoad(loadToDelete);
      closeConfirmationModal();
    }
  };

  const openPdfSelectionModal = (event, loadItemId) => {
    event.stopPropagation();
    setSelectedLoadItemId(loadItemId);
    setIsPdfSelectionModalOpen(true);
  };

  const closePdfSelectionModal = () => {
    setIsPdfSelectionModalOpen(false);
  };

  const handlePdfSelection = (pdfType) => {
    setIsPdfSelectionModalOpen(false);
    if (pdfType === 'gatepass') {
      setIsGatePassModalOpen(true);
    } else if (pdfType === 'others') {
      setIsOthersModalOpen(true);
    } else if (pdfType === 'release') {
      setIsReleaseModalOpen(true);
    }
  };

  const handleOpenPopup = (event) => {
    event.stopPropagation();
    setIsPopupOpen(true);
    setIsCurrentStatusPopupOpen(false);
    setIsStatusModalOpen(false);
    setIsDetailsModalOpen(false);
    setIsSendDocumentModalOpen(false);
    setIsAddPhotosModalOpen(false);
    setIsGatePassModalOpen(false);
    setIsConfirmationModalOpen(false);
    setIsPdfSelectionModalOpen(false);
    setIsOthersModalOpen(false);
    setIsReleaseModalOpen(false);
    setIsOptionsModalOpen(false);
    setIsPdfGatePassModalOpen(false);
  };

  const handleClickOutside = (event) => {
    if (currentStatusPopupRef.current && !currentStatusPopupRef.current.contains(event.target)) {
      setIsCurrentStatusPopupOpen(false);
    }
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOpenPdfGatePassModal = (loadTransportId) => {
    setSelectedLoadTransportId(loadTransportId);
    setIsPdfGatePassModalOpen(true);
    setDropdownOpen(false); // Close the dropdown when opening the GatePass modal
  };

  const handleClosePdfGatePassModal = () => {
    setIsPdfGatePassModalOpen(false);
    setSelectedLoadTransportId(null);
  };

  const handleOpenCurrentStatusPopup = (event) => {
    event.stopPropagation();
    setIsCurrentStatusPopupOpen(true);
    setIsPopupOpen(false);
    setIsStatusModalOpen(false);
    setIsDetailsModalOpen(false);
    setIsSendDocumentModalOpen(false);
    setIsAddPhotosModalOpen(false);
    setIsGatePassModalOpen(false);
    setIsConfirmationModalOpen(false);
    setIsPdfSelectionModalOpen(false);
    setIsOthersModalOpen(false);
    setIsReleaseModalOpen(false);
    setIsOptionsModalOpen(false);
    setIsPdfGatePassModalOpen(false);
  };

  const handleDeleteLoad = async (id) => {
    const userToken = localStorage.getItem('userToken');
    try {
      await axios.delete(`${process.env.REACT_APP_API_PREFIX}/loadtransport/${id}`, {
        headers: {
          "Screen-Name": "/Load",
          "Authorization": `Bearer ${userToken}`
        }
      });

      Swal.fire({
        title: 'Success!',
        text: 'Load deleted successfully!',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });

      setDeleted(true);
    } catch (error) {
      console.error("There was an error deleting the load:", error);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to delete load. Please try again.',
        icon: 'error',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    }
  };

  useEffect(() => {
    const loadItem = transport.load_items.find(item => item.gate_passes && item.gate_passes.length > 0);
    if (loadItem) {
      setGatePassUrl(`${process.env.REACT_APP_API_FILE_PREFIX}/${loadItem.gate_passes[0].path}`);
    } else {
      setGatePassUrl('');
    }
  }, [transport]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUpdateStatus = useCallback(async (event, id) => {
    event.stopPropagation();

    // Check if the current status is "Confirmed" or "Picked Up"
    if (["Confirmed", "Picked Up"].includes(currentStatus)) {
      Swal.fire({
        title: 'Action Not Allowed',
        text: 'You need to complete an inspection.',
        icon: 'warning',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
      return;
    }

    // Show confirmation alert before updating the status
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'The status will be updated and some information may not be adjustable anymore.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'No, keep it',
    });

    if (result.isConfirmed) {
      const userToken = localStorage.getItem('userToken');
      try {
        const transportResponse = await axios.post(`${process.env.REACT_APP_API_PREFIX}/loadtransport/lifecycle/${id}`, {}, {
          headers: {
            "Screen-Name": "/NewLoad",
            "Authorization": `Bearer ${userToken}`
          }
        });

        if (transportResponse.data) {
          const nextStatusName = findStatusName(transportResponse.data.data.load_status_id);
          setCurrentStatus(nextStatusName);
          statusCache[id] = { ...statusCache[id], load_status_name: nextStatusName }; // Update the cache

          if (nextStatusName === "Dropped Off") {
            const configureResponse = await axios.post(`${process.env.REACT_APP_API_PREFIX}/loadtransport/configure_status/${id}`, {}, {
              headers: {
                "Screen-Name": "/NewLoad",
                "Authorization": `Bearer ${userToken}`
              }
            });

            if (configureResponse.data) {
              const configureStatusName = findStatusName(configureResponse.data.data.load_status_id);
              setCurrentStatus(configureStatusName);
              statusCache[id] = { ...statusCache[id], load_status_name: configureStatusName }; // Update the cache
            }
          }

          if (nextStatusName === "Picked Up") {
            setPickupInspectionDone(true);
            statusCache[id] = { ...statusCache[id], pickup_inspection_done: true }; // Update the cache
          }

          if (nextStatusName === "Delivered") {
            setDeliveryInspectionDone(true);
            statusCache[id] = { ...statusCache[id], delivery_inspection_done: true }; // Update the cache
          }

          Swal.fire({
            title: 'Updated!',
            text: 'Status updated successfully.',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          }).then(() => {
            window.location.reload();
          });
        } else {
          console.error("No valid next status found.");
        }
      } catch (error) {
        console.error("Error updating status:", error);
        Swal.fire({
          title: 'Error!',
          text: 'Failed to update status. Please try again.',
          icon: 'error',
          showConfirmButton: false,
          timer: 2000
        });
      }
    } else {
      Swal.fire({
        title: 'Cancelled',
        text: 'Status update was cancelled.',
        icon: 'info',
        showConfirmButton: false,
        timer: 2000
      });
    }
  }, [currentStatus]);

  if (deleted) {
    return null;
  }

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const openGatePassModal = (event, loadItemId) => {
    event.stopPropagation();
    if (["New", "Assigned", "Confirmed"].includes(currentStatus)) {
      setSelectedLoadItemId(loadItemId);
      setIsGatePassModalOpen(true);
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'GatePass can only be added when status is New, Assigned, or Confirmed.',
        icon: 'error',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    }
  };

  const closeGatePassModal = () => {
    setIsGatePassModalOpen(false);
  };

  const openAddPhotosModal = (event) => {
    event.stopPropagation();
    setSelectedTransportId(transport.id);
    setIsAddPhotosModalOpen(true);
  };

  const closeAddPhotosModal = () => {
    setIsAddPhotosModalOpen(false);
  };

  const handleSendDocument = async ({ documentType, customerEmail }) => {
    const API_URL = `${process.env.REACT_APP_API_PREFIX}/loadtransport/send_documents/${selectedTransportId}`;

    try {
      const response = await axios.post(API_URL, {
        email: customerEmail,
        type: documentType
      }, {
        headers: {
          "Screen-Name": "/Load",
          "Authorization": `Bearer ${localStorage.getItem('userToken')}`
        }
      });

      await Swal.fire({
        title: 'Success!',
        text: 'Document sent successfully!',
        icon: 'success',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      }).then(() => {
        setIsSendDocumentModalOpen(false);
        history.push('/Load');
      });
    } catch (error) {
      let errorMessage = 'Request failed';
      if (error.response && error.response.data) {
        errorMessage = `${error.response.data.message} - ${error.response.data.error}`;
      }

      await Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    }
  };

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const openStatusModal = (event) => {
    event.stopPropagation();
    setIsDetailsModalOpen(false);
    setIsStatusModalOpen(true);
  };

  const closeStatusModal = () => setIsStatusModalOpen(false);

  const onCardClick = () => {
    if (isStatusModalOpen || isSendDocumentModalOpen) {
      return;
    }
  };

  const closeDetailsModal = () => {
    setIsDetailsModalOpen(false);
  };

  const getNextStatusId = (currentStatusId) => {
    const currentIndex = statusList.findIndex(s => s.id === currentStatusId);
    if (currentIndex >= 0 && currentIndex < statusList.length - 1) {
      return statusList[currentIndex + 1].id;
    } else {
      return null;
    }
  };

  const goToInspectionPage = (id_load_item) => {
    setIsPickupButtonDisabled(true);
    history.push(`/InspectionLoadItem?id_load_item=${id_load_item}`);
  };

  const goToDeliveryInspectionPage = (id_load_item) => {
    setIsDeliveryButtonDisabled(true);
    history.push(`/InspectionLoadItem?id_load_item=${id_load_item}`);
  };

  const openBOL = (bolPath) => {
    if (bolPath) {
      window.open(`${process.env.REACT_APP_API_FILE_PREFIX}/${bolPath}`, '_blank');
    } else {
      alert("BOL path is not available");
    }
  };

  const openInvoice = (invoicePath) => {
    if (invoicePath) {
      window.open(`${process.env.REACT_APP_API_FILE_PREFIX}/${invoicePath}`, '_blank');
    } else {
      alert("Invoice path is not available");
    }
  };

  // // Buttons to be rendered.
  // const renderInspectionButton = (loadItem) => {
  //   if (!currentStatus) {
  //     return null;
  //   }

  //   const lowerCaseStatus = currentStatus.toLowerCase();
  //   const pickupAvailable = loadItem.pickup_bol_path !== null;
  //   const deliveryAvailable = loadItem.dropoff_bol_path !== null;

  //   return (
  //     <>
  //       {shouldShowPickupButton(transport, loadItem) && (
  //         <button
  //           onClick={() => goToInspectionPage(loadItem.id)}
  //           className="flex items-center justify-center cursor-pointer bg-orange-500 text-white py-2 px-4 rounded-lg transition-transform transform hover:scale-105 ml-4"
  //           disabled={isPickupButtonDisabled}
  //         >
  //           <FiCheck className="mr-2 text-lg" />
  //           <span className="text-xs font-bold uppercase flex items-center">Start Pickup Inspection</span>
  //         </button>
  //       )}

  //       {shouldShowDeliveryButton(transport, loadItem) && (
  //         <div className="flex space-x-4" style={{ height: '3rem' }}>

  //           {transport.load_items[0]?.pickup_bol_path && (
  //             <li
  //               onClick={() => openBOL(transport.load_items[0].pickup_bol_path)}
  //               className="flex items-center justify-center cursor-pointer bg-green-500 text-white py-2 px-4 rounded-lg transition-transform transform hover:scale-105"
  //             >
  //               <FaFilePdf className="mr-2" /> Pickup
  //             </li>
  //           )}
  //           <button
  //             onClick={() => goToDeliveryInspectionPage(loadItem.id)}
  //             className="flex items-center justify-center cursor-pointer bg-orange-500 text-white py-2 px-4 rounded-lg transition-transform transform hover:scale-105 ml-4"
  //             disabled={isDeliveryButtonDisabled}
  //           >
  //             <FiCheck className="mr-2 text-lg" />
  //             <span className="text-xs font-bold uppercase flex items-center">Start Delivery Inspection</span>
  //           </button>
  //         </div>
  //       )}
  //     </>
  //   );
  // };

  // Buttons to be rendered.
  const renderInspectionButton = (loadItem) => {
    if (!currentStatus) {
      console.warn("renderInspectionButton: currentStatus is null or undefined.");
      return null;
    }

    // Verificar inspeções por tipo
    const pickupInspectionExists =
      Array.isArray(loadItem?.inspection_load_items) &&
      loadItem.inspection_load_items.some((item) => item.inspection_type === "Pickup");

    const deliveryInspectionExists =
      Array.isArray(loadItem?.inspection_load_items) &&
      loadItem.inspection_load_items.some((item) => item.inspection_type === "Delivery");

    // Definir lógica para exibição de botões
    const allInspectionsComplete = pickupInspectionExists && deliveryInspectionExists;
    const shouldShowPickupButton = !pickupInspectionExists && !allInspectionsComplete; // Exibe botão de Pickup se não houver inspeção e não estiver completo
    const shouldShowDeliveryButton =
      pickupInspectionExists && !deliveryInspectionExists && !allInspectionsComplete; // Exibe botão de Delivery se houver inspeção de Pickup, mas não de Delivery

    // Renderização condicional
    return (
      <>
        {/* Botão de Inspeção de Pickup */}
        {/* {shouldShowPickupButton && (
          <button
            onClick={() => {
              // console.log("Pickup button clicked for loadItem ID:", loadItem.id);
              goToInspectionPage(loadItem.id);
            }}
            className="flex items-center justify-center cursor-pointer bg-orange-500 text-white py-2 px-4 rounded-lg transition-transform transform hover:scale-105 ml-4"
            disabled={isPickupButtonDisabled}
          >
            <FiCheck className="mr-2 text-lg" />
            <span className="text-xs font-bold uppercase flex items-center">Start Pickup Inspection</span>
          </button>
        )} */}

        {shouldShowPickupButton && (
          <button
            onClick={() => {
              // console.log("Pickup button clicked for loadItem ID:", loadItem.id);
              goToInspectionPage(loadItem.id);
            }}
            className="flex items-center justify-center bg-orange-500 text-white py-1.5 px-4 rounded-md shadow-md transition-all transform hover:scale-105 hover:bg-orange-600 active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed ml-3"
            disabled={isPickupButtonDisabled}
          >
            <FiCheck className="text-lg mr-1.5" />
            <span className="text-sm font-semibold uppercase">
              Start Pickup
            </span>
          </button>
        )}







        {/* Botão de Inspeção de Delivery */}
        {/* {shouldShowDeliveryButton && (
          <div className="flex space-x-4" style={{ height: "3rem" }}>
            <button
              onClick={() => {
                // console.log("Delivery button clicked for loadItem ID:", loadItem.id);
                goToDeliveryInspectionPage(loadItem.id);
              }}
              className="flex items-center justify-center cursor-pointer bg-orange-500 text-white py-2 px-4 rounded-lg transition-transform transform hover:scale-105 ml-4"
              disabled={isDeliveryButtonDisabled}
            >
              <FiCheck className="mr-2 text-lg" />
              <span className="text-xs font-bold uppercase flex items-center">Start Delivery Inspection</span>
            </button>
          </div>
        )} */}

        {shouldShowDeliveryButton && (
          <button
            onClick={() => {
              // console.log("Pickup button clicked for loadItem ID:", loadItem.id);
              goToDeliveryInspectionPage(loadItem.id);
            }}
            className="flex items-center justify-center bg-orange-500 text-white py-1.5 px-4 rounded-md shadow-md transition-all transform hover:scale-105 hover:bg-orange-600 active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed ml-3"
            disabled={isDeliveryButtonDisabled}
          >
            <FiCheck className="text-lg mr-1.5" />
            <span className="text-sm font-semibold uppercase">
              Start Delivery
            </span>
          </button>
        )}
      </>
    );
  };



  //mb-4
  // const renderVehicleButtons = (vehicles) => {
  //   return (
  //     <div className="p-2">
  //       {vehicles.map((vehicle, index) => (
  //         <div key={index} id={`vehicle-${vehicle.id}`}>
  //           <div className="flex items-center justify-center cursor-pointer text-white py-2 px-4 rounded-lg transition-transform transform hover:scale-105 -mt-8">
  //             <span className="text-xs font-bold uppercase flex items-center">
  //               {renderInspectionButton(vehicle)}
  //             </span>
  //             <i className="fas fa-chevron-down rotate-180"></i>
  //           </div>
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };

  const renderVehicleButtons = (vehicles) => {
    return (
      <div className="p-4 space-y-4">
        {vehicles.map((vehicle) => (
          <div
            key={vehicle.id}
            id={`vehicle-${vehicle.id}`}
            className="flex items-center justify-center  text-white py-2 px-4 rounded-lg cursor-pointer transition"
          >
            {renderInspectionButton(vehicle)}
          </div>
        ))}
      </div>
    );
  };



  const statusColors = {
    "New": 'bg-light-blue-500 text-white',
    "Assigned": 'bg-green-500 text-white',
    "Confirmed": 'bg-red-500 text-white',
    "Picked Up": 'bg-blue-900 text-white',
    "Dropped Off": 'bg-yellow-600 text-white',
    "Delivered": 'bg-red-900 text-white',
    "Billed": 'bg-black text-white',
    "Paid": 'bg-lime-500 text-white',
    "Cancelled": 'bg-gray-700 text-white',
    "Archived": 'bg-orange-500 text-white'
  };

  const borderColors = {
    "New": 'border-light-blue-500',
    "Assigned": 'border-green-500',
    "Confirmed": 'border-red-500',
    "Picked Up": 'border-blue-500',
    "Dropped Off": 'border-yellow-600',
    "Delivered": 'border-red-500',
    "Billed": 'border-black',
    "Paid": 'border-lime-500',
    "Cancelled": 'border-gray-700',
    "Archived": 'border-orange-500'
  };

  const statusClass = statusColors[currentStatus] || 'bg-gray-100 text-gray-700';
  const borderClass = borderColors[currentStatus] || 'border-gray-300';

  const statusList = [
    { id: 1, name: "New" },
    { id: 2, name: "Assigned" },
    { id: 3, name: "Confirmed" },
    { id: 4, name: "Picked Up" },
    { id: 5, name: "Dropped Off" },
    { id: 6, name: "Delivered" },
    { id: 7, name: "Billed" },
    { id: 8, name: "Paid" },
    { id: 9, name: "Cancelled" },
    { id: 10, name: "Archived" },
  ];

  const findStatusDetails = (statusId) => {
    const status = statusList.find(s => s.id === statusId);
    return status || { name: "Unknown", color: "#000000" };
  };

  const findStatusName = (statusId) => {
    const status = statusList.find(s => s.id === statusId);
    return status ? status.name : "Unknown";
  };

  const openGatePassPDF = (event) => {
    event.stopPropagation();
    if (gatePassUrl) {
      window.open(gatePassUrl, '_blank');
    } else {
      alert('Gate Pass URL not available.');
    }
  };

  const openDetailsModal = () => {
    setSelectedTransport(transport);
    setIsDetailsModalOpen(true);
  };

  // const renderVehicleDetails = (vehicles) => {
  //   return vehicles.map((vehicle, index) => (
  //     <div key={index} className="mb-1">
  //       <div className="text-xs font-bold text-gray-800 uppercase">
  //         {vehicle.year} {vehicle.make} {vehicle.model}
  //       </div>

  //       <div className="relative">
  //         <button
  //           onClick={handleOpenPopup}
  //           className="flex items-center justify-center bg-orange-500 text-white py-2 px-4 rounded-lg transition-transform transform hover:scale-105 mt-1"
  //         >
  //           Open PDF
  //         </button>
  //         {isPopupOpen && (
  //           <div
  //             ref={popupRef}
  //             className="absolute z-10 bg-white border border-gray-300 rounded shadow-md mt-2 left-0 w-full sm:w-48"
  //           >
  //             <ul>
  //               <li
  //                 onClick={() => handleOpenPdfGatePassModal(transport.id)}
  //                 className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full"
  //               >
  //                 <FaDoorOpen className="mr-2" /> View All PDFs
  //               </li>
  //               {transport.load_items[0]?.dropoff_bol_path && (
  //                 <li
  //                   onClick={() => openBOL(transport.load_items[0].dropoff_bol_path)}
  //                   className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full"
  //                 >
  //                   <FaFilePdf className="mr-2" /> Open BOL
  //                 </li>
  //               )}
  //               {transport.invoice_pdf_path && (
  //                 <li
  //                   onClick={() => openInvoice(transport.invoice_pdf_path)}
  //                   className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full"
  //                 >
  //                   <FaFileInvoice className="mr-2" /> Open Invoice
  //                 </li>
  //               )}
  //             </ul>
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   ));
  // };

  const renderVehicleDetails = (vehicles) => {
    return vehicles.map((vehicle, index) => (
      <div key={index} className="mb-1">
        {/* <div className="text-xs font-bold text-gray-800 uppercase">
          {vehicle.year} {vehicle.make} {vehicle.model}
        </div> */}
        <div className="relative">
          <button
            onClick={handleOpenPopup}
            className="flex items-center justify-center bg-orange-500 text-white py-2 px-4 rounded-lg transition-transform transform hover:scale-105 mt-1"
          >
            Open PDF
          </button>
          {/* {isPopupOpen && (
            <div
              ref={popupRef}
              className="absolute z-10 bg-white border border-gray-300 rounded shadow-md mt-2 right-0"
              style={{ width: '12rem', marginRight: '-6rem' }}
            >
              <ul>
                <li onClick={() => handleOpenPdfGatePassModal(transport.id)} className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full">
                  <FaDoorOpen className="mr-2" /> View All PDFs
                </li>
                {transport.load_items[0]?.dropoff_bol_path && (
                  <li onClick={() => openBOL(transport.load_items[0].dropoff_bol_path)} className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full">
                    <FaFilePdf className="mr-2" /> Open BOL
                  </li>
                )}
                {transport.invoice_pdf_path && (
                  <li onClick={() => openInvoice(transport.invoice_pdf_path)} className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full">
                    <FaFileInvoice className="mr-2" /> Open Invoice
                  </li>
                )}
              </ul>
            </div>
          )} */}


          {/* {isPopupOpen && (
            <div
              ref={popupRef}
              className="absolute z-10 bg-white border border-gray-300 rounded shadow-md mt-2 left-0 w-full sm:w-48"
            >
              <ul>
                <li
                  onClick={() => handleOpenPdfGatePassModal(transport.id)}
                  className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full"
                >
                  <FaDoorOpen className="mr-2" /> View All PDFs
                </li>
                {transport.load_items[0]?.dropoff_bol_path && (
                  <li
                    onClick={() => openBOL(transport.load_items[0].dropoff_bol_path)}
                    className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full"
                  >
                    <FaFilePdf className="mr-2" /> Open BOL
                  </li>
                )}
                {transport.invoice_pdf_path && (
                  <li
                    onClick={() => openInvoice(transport.invoice_pdf_path)}
                    className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full"
                  >
                    <FaFileInvoice className="mr-2" /> Open Invoice
                  </li>
                )}
              </ul>
            </div>
          )} */}

          {isPopupOpen && (
            <div
              ref={popupRef}
              className="absolute z-10 bg-white border border-gray-300 rounded shadow-md mt-2 left-0 w-48"
            >
              <ul>
                <li
                  onClick={() => handleOpenPdfGatePassModal(transport.id)}
                  className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-2 text-sm transition duration-150 ease-in-out w-full"
                >
                  <FaDoorOpen className="mr-2 text-base" /> View All PDFs
                </li>
                {transport.load_items[0]?.dropoff_bol_path && (
                  <li
                    onClick={() => openBOL(transport.load_items[0].dropoff_bol_path)}
                    className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-2 text-sm transition duration-150 ease-in-out w-full"
                  >
                    <FaFilePdf className="mr-2 text-base" /> Open BOL
                  </li>
                )}
                {transport.invoice_pdf_path && (
                  <li
                    onClick={() => openInvoice(transport.invoice_pdf_path)}
                    className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-2 text-sm transition duration-150 ease-in-out w-full"
                  >
                    <FaFileInvoice className="mr-2 text-base" /> Open Invoice
                  </li>
                )}
              </ul>
            </div>
          )}

        </div >
      </div >
    ));
  };


  const renderVehicleDetailsInformation = (vehicles) => {
    return vehicles.map((vehicle, index) => (
      <div key={index} className="mb-1">
        <div className="text-xs font-bold text-gray-800 uppercase">
          {vehicle.year} {vehicle.make} {vehicle.model}
        </div>
      </div>
    ));
  };



  const handleSaveStatus = (newStatusName) => {
    setCurrentStatus(newStatusName);
  };

  const handleUpdateTransport = (updatedTransport) => {
    if (updatedTransport.load_status && updatedTransport.load_status.load_status_name) {
      setCurrentStatus(updatedTransport.load_status.load_status_name);
    }
    setDeleted(false);
  };

  const date = new Date(transport.expected_pickup_date);
  const dropoffDate = new Date(transport.expected_dropoff_date);

  return (
    <div className={`border ${borderClass} rounded-lg shadow-lg p-4`}>
      <div className="flex items-center justify-between mb-4">

        <div className="flex items-center">
          {transport.driver_id ? (
            <div className="flex items-center">
              <img
                className="rounded-full w-10 h-10"
                src={
                  transport.driver.user.profile_picture_path
                    ? `${process.env.REACT_APP_API_FILE_PREFIX}/${transport.driver.user.profile_picture_path}`
                    : fallbackImage
                }
                alt="Profile"
                onError={(e) => {
                  e.currentTarget.src = fallbackImage;
                }}
              />
              <span className="ml-2">{transport.driver.user.first_name} {transport.driver.user.last_name}</span>
            </div>
          ) : (
            <span>Driver not assigned</span>
          )}
          {/* <h6 className="ml-2 text-gray-600 text-sm">
            {transport.user.first_name}
          </h6> */}
        </div>



        <div className="relative">
          <button
            onClick={handleOpenCurrentStatusPopup}
            className={`badge text-white px-4 py-2 rounded-full ${statusClass}`}
            style={{ paddingTop: '0.4rem' }}
          >
            {currentStatus}
          </button>
          {isCurrentStatusPopupOpen && (
            <div
              ref={currentStatusPopupRef}
              className="absolute z-10 bg-white border border-gray-300 rounded shadow-md mt-2 right-0"
              style={{ width: '12rem', marginRight: '-6rem' }}
            >
              <ul>
                <li
                  onClick={(event) => handleUpdateStatus(event, transport.id)}
                  className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full"
                >
                  <FaHistory className="text-black mr-2" style={{ fontSize: '1em' }} />
                  <span>Update Status</span>
                </li>
                {!["Picked Up", "Dropped Off", "Delivered", "Billed", "Paid", "Cancelled", "Archived"].includes(currentStatus) && (
                  <li
                    className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full"
                    onClick={openStatusModal}
                  >
                    <GrConfigure className="mr-2 text-xl" />
                    <span>Change Status</span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>

        <div className="relative" ref={dropdownRef}>
          <button className="p-2 rounded-full hover:bg-gray-100 not-card-click focus:outline-none" onClick={toggleDropdown}>
            <IoEllipsisVertical className="text-gray-600 text-3xl" />
          </button>
          {dropdownOpen && (
            <div className="dropdown-menu origin-top-right absolute right-0 w-56 rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20 mt-2 transition ease-out duration-100">
              {!["Picked Up", "Dropped Off", "Delivered", "Billed", "Paid", "Cancelled", "Archived"].includes(currentStatus) && (
                <button className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full" onClick={openStatusModal}>
                  <GrConfigure className="mr-2 text-xl" />
                  Change Status
                </button>
              )}
              {["New", "Assigned", "Confirmed"].includes(currentStatus) && (
                transport.load_items.map((loadItem) => (
                  <div key={loadItem.id} className="relative">
                    <button
                      className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w/full"
                      onClick={(event) => openPdfSelectionModal(event, loadItem)}>
                      <IoTicket className="mr-2 text-xl" />
                      GatePass
                    </button>
                  </div>
                ))
              )}
              <button className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full" onClick={openAddPhotosModal}>
                <IoMdAdd className="mr-2 text-xl" />
                Add Photo
              </button>
              <button className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full" onClick={(event) => {
                event.stopPropagation();
                setSelectedTransportId(transport.id);
                setIsSendDocumentModalOpen(true);
              }}>
                <MdAttachEmail className="mr-2 text-xl" />
                Send BOL by E-mail
              </button>
              <button className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full" onClick={(event) => {
                event.stopPropagation();
                openDetailsModal();
              }}>
                <GrFormView className="mr-2 text-xl" />
                View Details
              </button>
              <button
                className="dropdown-item text-gray-700 hover:bg-gray-200 flex items-center px-4 py-3 text-base transition duration-150 ease-in-out w-full"
                onClick={() => openConfirmationModal(transport.id)}
              >
                <RiDeleteBin5Line className="mr-2 text-xl" />
                Delete Load
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="mb-4">
        <div className="text-sm font-bold text-black uppercase mb-1">Order Id: #{transport.order_id}/{transport.id}</div>
        <div className="text-sm font-bold text-black uppercase mb-1">{transport.partner_company.partner_company_name}</div>
        <div className="text-sm text-gray-600 mb-1">Dispatch Date: {transport.dispatch_date}</div>
        <div className="flex justify-between items-center mb-1">
          <div className="font-bold text-gray-800 inline-flex items-center">
            <FaMapMarkerAlt className="text-green-500 mr-1" />
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(transport.pickup_address + ', ' + transport.pickup_city + ', ' + transport.pickup_state + ' ' + transport.pickup_zipcode)}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-black hover:underline"
            >
              {transport.pickup_city}, {transport.pickup_state}
            </a>
          </div>
          <div className="text-uppercase text-gray-600 inline-flex items-center">
            <FaArrowAltCircleUp className="text-green-500 mr-1" />
            {transport.expected_pickup_date || new Date().toISOString().split('T')[0]}
          </div>
        </div>


        <div className="flex justify-between items-center mb-1">
          <div className="font-bold text-gray-800 inline-flex items-center">
            <FaMapMarkerAlt className="text-green-500 mr-1" />
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(transport.delivery_city + ', ' + transport.delivery_state)}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-black hover:underline"
            >
              {transport.delivery_city}, {transport.delivery_state}
            </a>
          </div>
          <div className="text-uppercase text-gray-600 inline-flex items-center">
            <FaArrowAltCircleDown className="text-red-500 mr-1" />
            {transport.expected_dropoff_date || new Date().toISOString().split('T')[0]}
          </div>
        </div>

      </div>

      <div className=" pt-0">

        {/* <div className="flex justify-between items-center mb-4">
          <div>
            <div className="text-xs font-bold text-black uppercase mb-1">Vehicle Details</div>
            {transport.load_items && renderVehicleDetails(transport.load_items)}
          </div>
          <div className="text-right">
            <div className="text-xs font-bold text-black uppercase mb-1">Payment Rate</div>
            <div className="text-sm font-bold text-gray-800">${transport.payment_rate}</div>
          </div>
        </div>

        {transport.load_items && renderVehicleButtons(transport.load_items)} */}


        <div className="flex flex-col gap-4">
          {/* Cabeçalho do card com botão para expandir/recolher tudo */}
          <div
            className="flex items-center justify-between cursor-pointer border-b pb-4 mb-4"
            onClick={toggleExpand}
          >
            <div className="text-left">
              <div className="text-xs font-bold text-black uppercase mb-1">
                Vehicle Details
              </div>
              {transport.load_items && renderVehicleDetailsInformation(transport.load_items)}
            </div>

            {/* Indicador de expansão */}
            {/* <div className="text-sm font-bold text-gray-800">
              {isExpanded ? 'Hide Details ▲' : 'Show Details ▼'}
              {isExpanded ? '' : ''}
            </div> */}

            <button onClick={(event) => {
              event.stopPropagation();
              toggleExpand(transport.id);
            }}>
              {isExpanded[transport.id] ? "" : ""}
            </button>


            <div className="text-right mt-4">
              <div className="text-xs font-bold text-black uppercase mb-1">
                Payment Rate
              </div>
              <div className="text-sm font-bold text-gray-800">
                ${transport.payment_rate}
              </div>
            </div>
          </div>

          {/* Conteúdo expandido */}
          {isExpanded && (
            <div>
              {transport.load_items &&
                transport.load_items.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between mb-4 border-b pb-4"
                  >
                    {/* Informações do veículo (à esquerda) */}

                    <div className="text-left flex-1 sm:w-1/2">
                      {/* <div className="text-xs font-bold text-black uppercase mb-1">
                        Vehicle Details
                      </div> */}
                      {/* Botão renderizado com tamanho ajustado para cada tela */}
                      <div className="w-full sm:w-auto">
                        {renderVehicleDetails([item])}
                      </div>
                    </div>

                    {/* Botões de ação para o veículo (à direita) */}
                    <div className="flex items-center justify-center ml-4 mt-4 sm:mt-0 sm:w-32">
                      {/* Botão renderizado com tamanho ajustado para cada tela */}
                      {renderVehicleButtons([item])}
                    </div>
                  </div>
                ))}

              {/* Taxa de pagamento (fora do loop, porque não está atrelada a um item específico) */}
              {/* <div className="text-right mt-4">
                <div className="text-xs font-bold text-black uppercase mb-1">
                  Payment Rate
                </div>
                <div className="text-sm font-bold text-gray-800">
                  ${transport.payment_rate}
                </div>
              </div> */}
            </div>
          )}
        </div>















        <AddPhotosModal
          isOpen={isAddPhotosModalOpen}
          onClose={closeAddPhotosModal}
          idTransport={selectedTransportId}
          loadItems={transport.load_items}
        />

        <SendDocumentModal
          isOpen={isSendDocumentModalOpen}
          onClose={() => setIsSendDocumentModalOpen(false)}
          onSend={handleSendDocument}
          transportId={selectedTransportId}
        />

        <StatusModal
          isOpen={isStatusModalOpen}
          onClose={closeStatusModal}
          onSave={handleSaveStatus}
          transport={selectedTransport || transport}
          currentStatus={currentStatus}
        />

        <TransportDetailsModal
          isOpen={isDetailsModalOpen}
          onClose={closeDetailsModal}
          transport={selectedTransport}
        />

        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          onRequestClose={closeConfirmationModal}
          onConfirm={handleConfirmDelete}
          message="Are you sure you want to delete this load?"
        />

        <PdfSelectionModal
          isOpen={isPdfSelectionModalOpen}
          onClose={() => setIsPdfSelectionModalOpen(false)}
          onSelect={handlePdfSelection}
        />

        <GatePassModal
          isOpen={isGatePassModalOpen}
          onClose={() => setIsGatePassModalOpen(false)}
          idLoadTransport={transport.id}
          loadItems={transport.load_items}
          selectedLoadItemId={selectedLoadItemId}
        />

        <ReleaseModal
          isOpen={isReleaseModalOpen}
          onClose={() => setIsReleaseModalOpen(false)}
          idLoadTransport={transport.id}
          loadItems={transport.load_items}
          selectedLoadItemId={selectedLoadItemId}
        />

        <OthersModal
          isOpen={isOthersModalOpen}
          onClose={() => setIsOthersModalOpen(false)}
          idLoadTransport={transport.id}
          loadItems={transport.load_items}
          selectedLoadItemId={selectedLoadItemId}
        />

        <OptionsModal
          isOpen={isOptionsModalOpen}
          onClose={() => setIsOptionsModalOpen(false)}
          transport={transport}
          onOpenPdfGatePassModal={handleOpenPdfGatePassModal}
        />
        <PdfGatePassModal
          isOpen={isPdfGatePassModalOpen}
          onClose={handleClosePdfGatePassModal}
          loadTransportId={selectedLoadTransportId}
        />
      </div>
    </div >
  );
};

export default Card;