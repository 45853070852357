import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Modal from 'react-modal';
import ReCAPTCHA from "react-google-recaptcha";
import '../components/css/RegisterForm.css';
import SignatureCanvas from 'react-signature-canvas';
const CarrierRegisterForm = ({ onBack }) => {
    const [companyName, setCompanyName] = useState('');
    const [companyPhone, setCompanyPhone] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [companyDot, setCompanyDot] = useState('');
    const [companyCity, setCompanyCity] = useState('');
    const [companyCountry, setCompanyCountry] = useState('');
    const [companyState, setCompanyState] = useState('');
    const [companyStreet, setCompanyStreet] = useState('');
    const [companyZipcode, setCompanyZipcode] = useState('');
    const [allowedToOperate, setAllowedToOperate] = useState(null);
    const [legalName, setLegalName] = useState('');
    const [usdot, setUsdot] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [recaptchaValue, setRecaptchaValue] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [signature, setSignature] = useState('');
    const [trailerType, setTrailerType] = useState('open');
    const sigCanvas = useRef(null);
    const history = useHistory(); // Use the useHistory hook

    const fetchUsdotData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_PREFIX}/dot_info/${usdot}`);
            console.log('Response:', response);

            if (response.data && response.data.content && response.data.content.carrier) {
                const carrierData = response.data.content.carrier;
                setLegalName(carrierData.legalName);
                setCompanyName(carrierData.legalName);
                setCompanyCity(carrierData.phyCity);
                setCompanyCountry(carrierData.phyCountry);
                setCompanyState(carrierData.phyState);
                setCompanyStreet(carrierData.phyStreet);
                setCompanyZipcode(carrierData.phyZipcode);
                setAllowedToOperate(carrierData.allowedToOperate === 'Y' ? '1' : '0');
            } else {
                console.error("No carrier data found");
            }
        } catch (error) {
            console.error('Error fetching USDOT data:', error);
            Swal.fire('Erro!', 'Não foi possível buscar os dados do USDOT.', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSignature = () => {
        setSignature(sigCanvas.current.toDataURL()); // Captura a assinatura em formato base64
    };

    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //     setIsLoading(true);

    //     try {
    //         const partnerCompanyId = localStorage.getItem('companyId') || 1;

    //         const carrierData = {
    //             email: companyEmail,
    //             user_role_id: partnerCompanyId ?? 4, // Usa 4 apenas se partnerCompanyId for null ou undefined
    //             first_name: firstName,
    //             last_name: lastName,
    //             phone: companyPhone,
    //             username,
    //             c_password: passwordConfirmation,
    //             password,
    //             partner_company_name: companyName || legalName,
    //             address: companyStreet || companyAddress,
    //             city: companyCity,
    //             state: companyState,
    //             zipcode: companyZipcode,
    //             dot: companyDot || usdot,
    //             is_allowed_to_operate: allowedToOperate,
    //             trailer_type: trailerType, // Adiciona o tipo de trailer
    //             signature, // Adiciona a assinatura capturada
    //         };

    //         console.log("Carrier Data:", JSON.stringify(carrierData, null, 2));

    //         const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/user-carrier`, carrierData);

    //         console.log("Response from server:", response.data);

    //         localStorage.setItem('registrationSuccess', 'true');

    //         Swal.fire({
    //             title: 'Success!',
    //             text: 'Registration successful! Check your email to confirm.',
    //             icon: 'success',
    //             confirmButtonText: 'Ok'
    //         }).then(() => {
    //             window.location.reload();
    //         });

    //         localStorage.removeItem('companyId');
    //     } catch (err) {
    //         console.error("Error during registration:", err);
    //         setError("Registration not completed! An error occurred.");
    //         if (err.response && err.response.data) {
    //             Swal.fire({
    //                 title: 'Error!',
    //                 text: `Registration not completed! ${err.response.data.message}`,
    //                 icon: 'error',
    //                 confirmButtonText: 'OK'
    //             });
    //         } else {
    //             Swal.fire({
    //                 title: 'Error!',
    //                 text: 'Registration not completed! An unknown error occurred.',
    //                 icon: 'error',
    //                 confirmButtonText: 'OK'
    //             });
    //         }
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    console.log(`${process.env.REACT_APP_API_PREFIX}/user-carrier`);


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const partnerCompanyId = localStorage.getItem('companyId') || 1;

            // Converte a assinatura base64 para um arquivo Blob
            const base64Data = signature.replace(/^data:image\/(png|jpeg);base64,/, '');
            const binaryData = atob(base64Data);
            const arrayBuffer = new Uint8Array(binaryData.length);
            for (let i = 0; i < binaryData.length; i++) {
                arrayBuffer[i] = binaryData.charCodeAt(i);
            }
            const blob = new Blob([arrayBuffer], { type: 'image/png' });

            // Cria o FormData para envio
            const formData = new FormData();
            formData.append('email', companyEmail);
            formData.append('user_role_id', partnerCompanyId ?? 4);
            formData.append('first_name', firstName);
            formData.append('last_name', lastName);
            formData.append('phone', companyPhone);
            formData.append('username', username);
            formData.append('c_password', passwordConfirmation);
            formData.append('password', password);
            formData.append('partner_company_name', companyName || legalName);
            formData.append('address', companyStreet || companyAddress);
            formData.append('city', companyCity);
            formData.append('state', companyState);
            formData.append('zipcode', companyZipcode);
            formData.append('dot', companyDot || usdot);
            formData.append('is_allowed_to_operate', allowedToOperate);
            formData.append('trailer_type', trailerType);
            formData.append('signature', blob, 'signature.png'); // Adiciona a assinatura como arquivo

            const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/user-carrier`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            Swal.fire({
                title: 'Success!',
                text: 'Registration successful! Check your email to confirm.',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(() => {
                window.location.reload();
            });

            localStorage.removeItem('companyId');
        } catch (err) {
            Swal.fire({
                title: 'Error!',
                text: 'Registration not completed! An unknown error occurred.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } finally {
            setIsLoading(false);
        }
    };



    const onChange = (value) => {
        console.log("Captcha value:", value);
        setRecaptchaValue(value);
    };

    return (
        <Modal isOpen={true} onRequestClose={onBack} contentLabel="Carrier Registration" className="flex items-center justify-center min-h-screen">
            <div className="bg-white rounded-lg shadow-lg p-6 mx-4 md:max-w-4xl w-full overflow-auto custom-scrollbar mobile_register" style={{ maxHeight: '94vh', maxWidth: '41rem' }}>
                <a href="#" className="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img className="w-12 h-12" src="./cajuu.png" alt="logo" />
                </a>
                <h2 className="text-2xl font-bold text-center mb-6">Carrier Registration</h2>

                <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
                    {message && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">{message}</div>}
                    {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{error}</div>}

                    <div>
                        <label htmlFor="usdot" className="block mb-2 text-sm font-medium text-gray-900 mt-4">USDOT Number:</label>
                        <input
                            id="usdot"
                            name="usdot"
                            type="text"
                            value={usdot}
                            onChange={(e) => setUsdot(e.target.value)}
                            required
                            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="USDOT Registration Number"
                        />
                        <button
                            type="button"
                            onClick={fetchUsdotData}
                            disabled={isLoading}
                            className="w-full text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ml-1 mt-4"
                        >
                            {isLoading ? 'Seeking out...' : 'Search USDOT'}
                        </button>
                    </div>

                    <div>
                        <label htmlFor="legalName" className="block mb-2 text-sm font-medium text-gray-900">Company Name:</label>
                        <input
                            type="text"
                            value={companyName}
                            readOnly
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                        />
                    </div>
                    <div>
                        <label htmlFor="phyStreet" className="block mb-2 text-sm font-medium text-gray-900">Street Address:</label>
                        <input
                            id="phyStreet"
                            type="text"
                            value={companyStreet}
                            readOnly
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                        />
                    </div>

                    <div className="flex flex-col md:flex-row justify-between md:space-x-4 space-y-4 md:space-y-0">
                        <div className="flex-1 min-w-0">
                            <label htmlFor="phyZipcode" className="block mb-2 text-sm font-medium text-gray-900">Zipcode:</label>
                            <input
                                id="phyZipcode"
                                type="text"
                                value={companyZipcode}
                                readOnly
                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                            />
                        </div>
                        <div className="flex-1 min-w-0">
                            <label htmlFor="phyCity" className="block mb-2 text-sm font-medium text-gray-900">City:</label>
                            <input
                                id="phyCity"
                                type="text"
                                value={companyCity}
                                readOnly
                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                            />
                        </div>
                        <div className="flex-1 min-w-0 md:mt-0 mt-4">
                            <label htmlFor="phyState" className="block mb-2 text-sm font-medium text-gray-900">State:</label>
                            <input
                                id="phyState"
                                type="text"
                                value={companyState}
                                readOnly
                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                            />
                        </div>
                        <div className="flex-1 min-w-0 md:mt-0 mt-4">
                            <label htmlFor="phyCountry" className="block mb-2 text-sm font-medium text-gray-900">Country:</label>
                            <input
                                id="phyCountry"
                                type="text"
                                value={companyCountry}
                                readOnly
                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
                            />
                        </div>
                        {allowedToOperate !== null && (
                            <div className="p-4 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 md:ml-4 mt-4 md:mt-0">
                                <label htmlFor="allowedToOperate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Allowed to Operate:</label>
                                <div className={`flex items-center space-x-2`}>
                                    <span className={`font-bold text-lg ${allowedToOperate === '1' ? 'text-green-500' : 'text-red-500'}`}>
                                        {allowedToOperate === '1' ? 'Yes' : 'No'}
                                    </span>
                                    <svg className={`${allowedToOperate === '1' ? 'text-green-500' : 'text-red-500'} w-6 h-6`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        {allowedToOperate === '1' ? (
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        ) : (
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        )}
                                    </svg>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="flex space-x-2">
                        <div className="flex-1">
                            <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-900">First Name:</label>
                            <input
                                id="firstName"
                                name="firstName"
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                                className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                placeholder="First Name"
                            />
                        </div>
                        <div className="flex-1">
                            <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-900">Last Name:</label>
                            <input
                                id="lastName"
                                name="lastName"
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                                className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Last Name"
                            />
                        </div>
                    </div>

                    <div className="flex space-x-2">
                        <div className="flex-1">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                            <input
                                type="email"
                                value={companyEmail}
                                onChange={(e) => setCompanyEmail(e.target.value)}
                                className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                placeholder="name@company.com"
                                required
                            />
                        </div>
                        <div className="flex-1">
                            <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Username"
                                required
                            />
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <div className="flex-1">
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 mt-4">Password:</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Password"
                                required
                                autoComplete="new-password"
                            />
                        </div>
                        <div className="flex-1">
                            <label htmlFor="passwordConfirmation" className="block mb-2 text-sm font-medium text-gray-900 mt-4">Confirmed Password:</label>
                            <input
                                type="password"
                                value={passwordConfirmation}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                                className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                placeholder="Confirm your password"
                                required
                            />
                        </div>
                    </div>
                    <div className="flex space-x-2">
                        <div className="flex-1">
                            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 mt-4">Phone:</label>
                            <input
                                id="phone"
                                name="phone"
                                type="tel"
                                value={companyPhone}
                                onChange={(e) => setCompanyPhone(e.target.value)}
                                required
                                className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="+1 123-456-7890"
                            />
                        </div>
                    </div>

                    {/* Trailer Type */}
                    <div>
                        <label htmlFor="trailerType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Trailer Type</label>
                        <div className="flex items-center space-x-4">
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    className="form-radio"
                                    name="trailerType"
                                    value="open"
                                    checked={trailerType === 'open'}
                                    onChange={(e) => setTrailerType(e.target.value)}
                                />
                                <span className="ml-2">Open</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    className="form-radio"
                                    name="trailerType"
                                    value="enclosed"
                                    checked={trailerType === 'enclosed'}
                                    onChange={(e) => setTrailerType(e.target.value)}
                                />
                                <span className="ml-2">Enclosed</span>
                            </label>
                        </div>
                    </div>

                    {/* Driver Signature */}
                    <div className="signature-section mt-4">
                        <label htmlFor="signature" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Driver Signature</label>
                        <div className="border-2 border-gray-300 rounded-lg p-4 bg-white dark:bg-gray-800">
                            <SignatureCanvas
                                penColor="black"
                                canvasProps={{ className: 'w-full h-48' }}
                                ref={sigCanvas}
                                onEnd={handleSignature}
                            />
                        </div>
                        <div className="flex justify-end mt-2">
                            <button
                                onClick={() => sigCanvas.current.clear()}
                                type="button"
                                className="text-red-500 hover:text-red-700"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>


                    <ReCAPTCHA
                        sitekey="6Le8GfwpAAAAAD6ud1WiozXOqjy2cakJcezWIEN6"
                        onChange={onChange}
                        className="flex justify-center my-4"
                    />

                    <div className="flex justify-evenly mt-6">
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="w-full text-white bg-orange-500 hover:bg-orange-700 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2"
                        >
                            {isLoading ? 'Registering...' : 'Register'}
                        </button>
                        <button
                            type="button"
                            onClick={() => history.push('/Login')}
                            className="w-full text-white bg-gray-500 hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ml-2"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default CarrierRegisterForm;