import React, { useState, useEffect, useRef } from "react";
import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";
import { DivideSquareIcon, FileWarning } from "lucide-react";
import SignatureSection from "../components/SignatureSection";
import { TbTrashX } from "react-icons/tb";
import Modal from "react-modal";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { GrNext } from "react-icons/gr";
import VehicleInspection from '../components/VehicleInspection';
import { FaCar } from "react-icons/fa";
import { FcInspection } from "react-icons/fc";
import { FaNoteSticky } from "react-icons/fa6";
import { FaPhotoFilm } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { FaFileSignature, FaCheckSquare, FaTools, FaRegQuestionCircle } from 'react-icons/fa';
import { FaWind, FaGlassWhiskey, FaFileAlt } from 'react-icons/fa';
import { FaSearch } from 'react-icons/fa';
import { FaBoxOpen } from 'react-icons/fa';
import MainSpinner from '../components/MainSpinner';

import "../App.css";
import { Input } from "@material-tailwind/react";

function InspectionLoadItemPage() {
  const [images, setImages] = useState({});
  const [deliveryImage, setDeliveryImage] = useState(null);
  const [cards, setCards] = useState([]); // Initialize as an empty array
  const MAIN_CARD_ID = "main";
  const [carouselModalIsOpen, setCarouselModalIsOpen] = useState(false);
  const [selectedCarouselImageIndex, setSelectedCarouselImageIndex] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const signatureRef = useRef();
  const diagramRef = useRef();

  const activateSpinner = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const [drivable, setDrivable] = useState("0"); // "0" for Yes, "1" for No
  const [windscreen, setWindscreen] = useState("0"); // "0" for Yes, "1" for No
  const [intactGlass, setIntactGlass] = useState("0"); // "0" for Yes, "1" for No
  const [paperwork, setPaperwork] = useState("0"); // "0" for Yes, "1" for No
  const [keysQt, setKeysQt] = useState('');
  const [spareTire, setSpareTire] = useState('');
  const [others, setOthers] = useState('');

  // Preload form data
  const [loadItem, setLoadItem] = useState(null);
  const [odometer, setOdometer] = useState('');
  const [CustomerName, setCustomerName] = useState('');
  const [CustomerEmail, setCustomerEmail] = useState('');
  const [CustomerPhone, setCustomerPhone] = useState('');

  useEffect(() => {
    const fetchLoadItem = async () => {
      const userToken = localStorage.getItem('userToken');
      const idLoadItem = new URLSearchParams(location.search).get('id_load_item');
      const baseURL = `${process.env.REACT_APP_API_FILE_PREFIX}`;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_PREFIX}/loaditem/${idLoadItem}`, {
          headers: {
            "Screen-Name": "/InspectionLoadItem",
            "Authorization": `Bearer ${userToken}`
          }
        });

        setLoadItem(response.data.data);

        const loadTransport = response.data.data.load_transport;

        // Set customer data from the pickup inspection
        setCustomerName(loadTransport.pickup_customer_name);
        setCustomerPhone(loadTransport.pickup_customer_phone);
        setCustomerEmail(loadTransport.pickup_customer_email);

        if (response.data.data.load_transport.load_status_id === 4) {
          const pickupInspection = response.data.data.inspection_load_items.find(
            item => item.inspection_type === "Pickup"
          );

          if (pickupInspection) {
            const storedOdometerValue = pickupInspection.odometer;
            if (storedOdometerValue) {
              setOdometer(storedOdometerValue);
            }

            // Set customer data from the delivery inspection
            setCustomerName(loadTransport.delivery_customer_name);
            setCustomerPhone(loadTransport.delivery_customer_phone);
            setCustomerEmail(loadTransport.delivery_customer_email);

            setDrivable(pickupInspection.drivable ? '0' : '1');
            setWindscreen(pickupInspection.windscreen ? '0' : '1');
            setIntactGlass(pickupInspection.intact_glass ? '0' : '1');
            setPaperwork(pickupInspection.paperwork ? '0' : '1');
            setKeysQt(pickupInspection.keys_qt);
            setSpareTire(pickupInspection.spare_tire ? 'true' : 'false');
            setOthers(pickupInspection.others);

          }

          const deliveryInspection = response.data.data.inspection_load_items[0]?.diagram_path;
          if (deliveryInspection !== null) {
            const fullDeliveryImageURL = `${baseURL}/${deliveryInspection}`;
            setDeliveryImage(fullDeliveryImageURL);
          }
        }
      } catch (error) {
        console.error("Error fetching item data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLoadItem();
  }, [location.search]);

  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);

  const openCarouselModal = (imageKey) => {
    const imageKeys = Object.keys(images[MAIN_CARD_ID]);
    const selectedIndex = imageKeys.indexOf(imageKey);
    setSelectedCarouselImageIndex(selectedIndex);
    setCarouselModalIsOpen(true);
  };

  const navigateCarousel = (direction) => {
    const imageKeys = Object.keys(images[MAIN_CARD_ID]);
    const newIndex = (selectedCarouselImageIndex + (direction === "next" ? 1 : imageKeys.length - 1)) % imageKeys.length;
    setSelectedCarouselImageIndex(newIndex);
  };

  const closeCarouselModal = () => setCarouselModalIsOpen(false);

  const handleFileChange = (cardId) => (event) => {
    const files = Array.from(event.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImages((prevImages) => ({
          ...prevImages,
          [cardId]: { ...prevImages[cardId], [Date.now()]: e.target.result },
        }));
      };
      reader.readAsDataURL(file);
    });
  };

  const excluirImagem = (cardId, imageKey) => {
    setImages((prevImages) => {
      const updatedImages = { ...prevImages };
      delete updatedImages[cardId][imageKey];
      return updatedImages;
    });
  };

  const adicionarNovoCard = () => {
    const novoId = Date.now(); // Creates an ID based on the current timestamp
    setCards((cardsAntigos) => [...cardsAntigos, novoId]); // Adds a new ID to the cards array
  };

  const excluirCard = (id) => {
    setCards((cardsAnteriores) =>
      cardsAnteriores.filter((cardId) => cardId !== id)
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Validate required fields
    const errors = validateFormData();
    if (Object.keys(errors).length > 0) {
      const errorMessages = Object.values(errors).join('<br />');
      Swal.fire({
        title: 'Error!',
        html: `Please fill out all required fields before submitting:<br /><br />${errorMessages}`,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
      setLoading(false); // End loading
      return;
    }

    const formData = new FormData();
    const odometerValue = document.getElementById('Odometer').value;
    const keysQtValue = parseInt(document.getElementById('keys_qt').value, 10);

    formData.append('load_item_id', loadItem.id);
    formData.append('inspection_type', document.getElementById('InspectionType').value);
    formData.append('odometer', odometerValue);
    formData.append('notes', document.getElementById('Notes').value);
    formData.append('customer_name', document.getElementById('CustomerName').value);
    formData.append('customer_phone', document.getElementById('CustomerPhone').value);
    formData.append('customer_email', document.getElementById('CustomerEmail').value);

    function toNumericString(value) {
      return value === 'true' ? '1' : '0';
    }

    formData.append('drivable', drivable);
    formData.append('intact_glass', intactGlass);
    formData.append('paperwork', paperwork);
    formData.append('windscreen', windscreen);
    formData.append('keys_qt', keysQtValue);
    formData.append('spare_tire', toNumericString(spareTire));

    if (images[MAIN_CARD_ID] && Object.keys(images[MAIN_CARD_ID]).length > 0) {
      const photoPromises = Object.values(images[MAIN_CARD_ID]).map((imageSrc, index) => {
        return fetch(imageSrc)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], `vehicle_image_${index}.jpg`, { type: 'image/jpeg' });
            formData.append('photos[]', file);
          });
      });
      await Promise.all(photoPromises);
    }

    const signatureDataUrl = signatureRef.current.save();
    try {
      const signatureBlob = await fetch(signatureDataUrl).then(res => res.blob());
      formData.append('customer_signature', new File([signatureBlob], 'customer_signature.png', { type: 'image/png' }));

      const inspectionType = document.getElementById('InspectionType').value;
      if (inspectionType === 'Pickup') {
        const diagramDataUrl = diagramRef.current.getDiagramImage();
        const diagramBlob = await fetch(diagramDataUrl).then(res => res.blob());
        formData.append('inspection_diagram', new File([diagramBlob], 'inspection_diagram.png', { type: 'image/png' }));
      }

      const userToken = localStorage.getItem('userToken');

      const response = await axios.post(`${process.env.REACT_APP_API_PREFIX}/inspectionloaditem`,
        formData,
        {
          headers: {
            "Screen-Name": "/InspectionLoadItem",
            "Authorization": `Bearer ${userToken}`,
            "Content-Type": "multipart/form-data"
          }
        }
      );

      if (inspectionType === 'Pickup') {
        localStorage.setItem('odometerValue', odometerValue);
      }

      Swal.fire({
        title: 'Success!',
        text: 'Inspection submitted successfully!',
        icon: 'success',
        showConfirmButton: false, // Do not show the confirmation button
        timer: 2000, // Time in milliseconds (2 seconds)
        timerProgressBar: true, // Displays the progress bar
      }).then(() => {
        history.push('/Load');
      });

    } catch (error) {
      console.error("Error submitting form data:", error);

      let errorMessage = 'Request failed';
      if (error.response && error.response.data) {
        errorMessage = `${error.response.data.message} - ${error.response.data.error}`;
      }

      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        showConfirmButton: false, // Do not show the confirmation button
        timer: 2000, // Time in milliseconds (2 seconds)
        timerProgressBar: true, // Displays the progress bar
      });
    } finally {
      setLoading(false);
    }
  };

  const validateFormData = () => {
    const errors = {};
    const odometerValue = document.getElementById('Odometer').value;
    const keysQtValue = parseInt(document.getElementById('keys_qt').value, 10);

    if (!odometerValue) {
      errors.odometer = 'Odometer is required.';
    }

    if (isNaN(keysQtValue)) {
      errors.keysQt = 'The keys qt must be an integer.';
    }

    return errors;
  };

  const isDeliveryInspection = loadItem?.load_transport.load_status_id === 4;

  return (
    <div className="px-4">
      {isLoading ? (
        <MainSpinner />
      ) : (
        <div className="flex flex-col">
          {/* General Info */}
          <Card className="mt-5 mx-2">
            <CardBody>
              <div className="mt-1 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
                <div className="flex items-center p-4 border-b border-gray-200 dark:border-gray-700">
                  <FaCar className="w-6 h-6 text-blue-500" style={{ color: 'orange' }} />
                  <h2 className="text-lg font-semibold ml-4 text-gray-900 dark:text-white">General Info</h2>
                </div>

                <div className="flex flex-wrap w-full p-4 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg">
                  <div className="px-2 w-full lg:w-1/3 mb-4 lg:mb-0">
                    <label htmlFor="LoadItem" className="flex text-sm font-medium leading-6 text-gray-900 dark:text-gray-300 mb-1">
                      Load Item:
                    </label>
                    {/* <select
                      id="LoadItem"
                      name="LoadItem"
                      className="block p-2.5 w-full text-sm bg-gray-200 border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      required
                      disabled
                    >
                      {loadItem ? (
                        <option key={loadItem.id} value={loadItem.id}>
                          {loadItem.year} {loadItem.make} {loadItem.model} - [{loadItem.vin}]
                        </option>
                      ) : (
                        <option>Loading...</option>
                      )}
                    </select> */}

                    <div style={{ position: "relative" }}>
                      <select
                        id="LoadItem"
                        name="LoadItem"
                        required
                        style={{
                          pointerEvents: "none", // isDeliveryInspection ? "none" : "auto", // Desativa interação no Delivery
                          backgroundColor: "#e5e7eb", // isDeliveryInspection ? "#e5e7eb" : "#fff", // Fundo cinza claro
                          color: "#6b7280", // isDeliveryInspection ? "#6b7280" : "#000", // Texto cinza
                          opacity: 0.8, //  isDeliveryInspection ? 0.8 : 1, // Suaviza a aparência
                          border: "1px solid #d1d5db",
                          padding: "6px",
                          borderRadius: "6px",
                          width: "100%",
                        }}
                        disabled={true} //{isDeliveryInspection}
                      >
                        {loadItem ? (
                          <option key={loadItem.id} value={loadItem.id}>
                            {loadItem.year} {loadItem.make} {loadItem.model} - [{loadItem.vin}]
                          </option>
                        ) : (
                          <option>Loading...</option>
                        )}
                      </select>
                      { true && ( //isDeliveryInspection && (
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.05)", // Camada transparente
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            pointerEvents: "none", // Impede interação
                            borderRadius: "6px",
                          }}
                        >
                        </div>
                      )}
                    </div>

                  </div>

                  <div className="px-2 w-full lg:w-1/3 mb-4 lg:mb-0">
                    <label htmlFor="InspectionType" className="flex text-sm font-medium leading-6 text-gray-900 dark:text-gray-300 mb-1">
                      Inspection Type:
                    </label>
                    <div style={{ position: "relative" }}>
                      <select
                        id="InspectionType"
                        name="InspectionType"
                        required
                        style={{
                          pointerEvents: "none", // isDeliveryInspection ? "none" : "auto", // Desativa interação no Delivery
                          backgroundColor: "#e5e7eb", // isDeliveryInspection ? "#e5e7eb" : "#fff", // Fundo cinza claro
                          color: "#6b7280", // isDeliveryInspection ? "#6b7280" : "#000", // Texto cinza
                          opacity: 0.8, // isDeliveryInspection ? 0.8 : 1, // Suaviza a aparência
                          border: "1px solid #d1d5db",
                          padding: "6px",
                          borderRadius: "6px",
                          width: "100%",
                        }}
                        disabled={true} // {isDeliveryInspection}
                      >
                        {loadItem ? ( 
                          <option
                            key={
                              loadItem.load_transport.load_status_id === 3
                                ? "Pickup"
                                : loadItem.load_transport.load_status_id === 4
                                  ? "Delivery"
                                  : "---"
                            }
                            value={
                              loadItem.load_transport.load_status_id === 3
                                ? "Pickup"
                                : loadItem.load_transport.load_status_id === 4
                                  ? "Delivery"
                                  : "---"
                            }
                          >
                            {loadItem.load_transport.load_status_id === 3
                              ? "Pickup"
                              : loadItem.load_transport.load_status_id === 4
                                ? "Delivery"
                                : "---"}
                          </option>
                        ) : (
                          <option>Loading...</option>
                        )}
                      </select>

                      {true && ( // isDeliveryInspection && (
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.05)", // Camada transparente
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            pointerEvents: "none", // Impede interação
                            borderRadius: "6px",
                          }}
                        >
                        </div>
                      )}
                    </div>

                  </div>

                  <div className="px-2 w-full lg:w-1/3">
                    <label htmlFor="Odometer" className="flex text-sm font-medium leading-6 text-gray-900 dark:text-gray-300 mb-1">
                      Odometer:
                    </label>
                    {/* <input
                      id="Odometer"
                      name="Odometer"
                      required
                      type="text"
                      className="block p-2.5 w-full text-sm bg-gray-200 border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      placeholder="Enter Odometer"
                      value={odometer}
                      onChange={(e) => setOdometer(e.target.value)}
                      readOnly={loadItem && loadItem.load_transport.load_status_id === 4} // Torna somente leitura na inspeção de entrega
                    /> */}

                    <div style={{ position: "relative" }}>
                      <input
                        id="Odometer"
                        name="Odometer"
                        required
                        type="text"
                        style={{
                          pointerEvents: isDeliveryInspection ? "none" : "auto", // Desativa interação no Delivery
                          backgroundColor: isDeliveryInspection ? "#e5e7eb" : "#fff", // Fundo cinza claro
                          color: isDeliveryInspection ? "#6b7280" : "#000", // Texto cinza
                          opacity: isDeliveryInspection ? 0.8 : 1, // Suaviza a aparência
                          border: "1px solid #d1d5db",
                          padding: "6px",
                          borderRadius: "6px",
                          width: "100%",
                        }}
                        placeholder="Enter Odometer"
                        value={odometer}
                        onChange={(e) => setOdometer(e.target.value)}
                        disabled={isDeliveryInspection}
                      />
                      {isDeliveryInspection && (
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.05)", // Camada transparente
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            pointerEvents: "none", // Impede interação
                            borderRadius: "8px",
                          }}
                        >
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          {/* Inspection Diagram */}
          <Card className="mt-5 ml-2 mr-4">
            <CardBody>
              <div className="mt-1 bg-white dark:bg-gray-800">
                <div className="flex items-center p-4">
                  <FcInspection className="w-6 h-6 text-blue-500" style={{ color: 'orange' }} />
                  <h2 className="text-lg font-semibold ml-4 text-gray-900 dark:text-white">Inspection Diagram</h2>
                </div>

                <div className="items-center justify-center w-full">
                  {loadItem ? (
                    <VehicleInspection
                      ref={diagramRef}
                      diagramaURL={loadItem.car_type.diagram_path}
                      tipoInspecao={loadItem.load_transport.load_status_id < 4 ? 'pickup' : 'dropoff'}
                      deliveryDiagramURL={deliveryImage}
                    />

                  ) : (
                    <option>Loading...</option>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
          {/* Photos of Vehicle */}
          <Card className="mt-5 ml-2 mr-4">
            <CardBody>
              <div className="mt-1 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
                <div className="flex items-center p-4 border-b border-gray-200 dark:border-gray-700">
                  <FaPhotoFilm className="w-6 h-6 text-blue-500" style={{ color: 'orange' }} />
                  <h2 className="text-lg font-semibold ml-4 text-gray-900 dark:text-white">Photos of Vehicle</h2>
                </div>

                <div className="p-4">
                  <div className="w-full bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg">
                    <label
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 dark:hover:bg-gray-800 dark:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500"
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                          className="Icone w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 16"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                          />
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">Click to upload</span> or drag and drop
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          SVG, PNG, JPG or GIF (MAX. 800x400px)
                        </p>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        multiple
                        className="hidden"
                        onChange={handleFileChange(MAIN_CARD_ID)}
                      />
                    </label>
                  </div>

                  <div className="flex flex-wrap ImagePhotos p-4">
                    {images[MAIN_CARD_ID] &&
                      Object.keys(images[MAIN_CARD_ID]).map((imageKey, index) => (
                        <div key={`image-${imageKey}`} className="p-4">
                          <div className="relative">
                            <img
                              src={images[MAIN_CARD_ID][imageKey]}
                              className="w-32 h-32 object-cover cursor-pointer"
                              alt="Uploaded"
                              style={{ width: "12rem", height: "10rem", borderRadius: "16px" }}
                              onClick={() => openCarouselModal(imageKey)} // Passing the image key
                            />
                            <button
                              onClick={() => excluirImagem(MAIN_CARD_ID, imageKey)}
                              className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                            >
                              <TbTrashX />
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          {/* Notes */}
          <Card className="mt-5 ml-2 mr-4">
            <CardBody>
              <div className="mt-1 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
                <div className="flex items-center p-4 border-b border-gray-200 dark:border-gray-700">
                  <FaNoteSticky className="w-6 h-6 text-orange-500" />
                  <h2 className="text-lg font-semibold ml-4 text-gray-900 dark:text-white">Notes</h2>
                </div>

                <div className="p-4">
                  <div className="w-full">
                    <textarea
                      id="Notes"
                      className="form-control w-full p-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:text-white"
                      rows="5"
                      placeholder="Enter your observations here..."
                    ></textarea>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          {/* Additional Inspection / Loose Items */}

          <Card className="mt-5 mx-2">
            <CardBody>
              <div className="mt-1 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4">
                <div className="flex flex-col md:flex-row md:justify-between gap-4">
                  <div className="flex-1">
                    <div className="border-b border-gray-200 dark:border-gray-700 pb-2 mb-2">
                      <h2 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
                        <FaSearch className="w-6 h-6 text-orange-500 mr-2" />
                        Additional Inspection
                      </h2>
                    </div>
                    <div className="bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg p-4">
                      <div className="flex flex-wrap items-center mb-2">
                        <FaCar className="w-6 h-6 text-blue-500" />
                        <p className="ml-4">Drivable:</p>
                        {/* <select
                          id="drivable"
                          className="ml-2 p-1 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded"
                          value={drivable}
                          onChange={(e) => setDrivable(e.target.value)}
                          disabled={isDeliveryInspection}
                        >
                          <option value="0">Yes</option>
                          <option value="1">No</option>
                        </select> */}


                        <div style={{ position: "relative", width: "auto", maxWidth: "120px", marginLeft: "0.5rem", }}>
                          <select
                            id="drivable"
                            style={{
                              pointerEvents: isDeliveryInspection ? "none" : "auto", // Desativa interação
                              backgroundColor: isDeliveryInspection ? "#e5e7eb" : "#fff", // Fundo cinza claro no Delivery
                              color: isDeliveryInspection ? "#6b7280" : "#000", // Texto cinza
                              opacity: isDeliveryInspection ? 0.8 : 1, // Aparência suavizada
                              border: "1px solid #d1d5db",
                              padding: "4px",
                              borderRadius: "6px",
                              width: "100%", // Responsivo até maxWidth
                              fontSize: "1rem", // Tamanho de fonte pequeno
                            }}
                            value={drivable}
                            onChange={(e) => setDrivable(e.target.value)}
                            disabled={isDeliveryInspection}
                          >
                            <option value="0">Yes</option>
                            <option value="1">No</option>
                          </select>

                          {/* Camada de sobreposição no Delivery */}
                          {isDeliveryInspection && (
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.05)", // Transparente
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                pointerEvents: "none", // Bloqueia interação
                                borderRadius: "6px",
                              }}
                            >
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-wrap items-center mb-2">
                        <FaWind className="w-6 h-6 text-green-500" />
                        <p className="ml-4">Windscreen:</p>
                        {/* <select
                          id="windscreen"
                          className="ml-2 p-1 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded"
                          value={windscreen}
                          onChange={(e) => setWindscreen(e.target.value)}
                          disabled={isDeliveryInspection}
                        >
                          <option value="0">Yes</option>
                          <option value="1">No</option>
                        </select> */}

                        <div style={{ position: "relative", width: "auto", maxWidth: "120px", marginLeft: "0.5rem", }}>
                          <select
                            id="windscreen"
                            style={{
                              pointerEvents: isDeliveryInspection ? "none" : "auto", // Desativa interação
                              backgroundColor: isDeliveryInspection ? "#e5e7eb" : "#fff", // Fundo cinza claro no Delivery
                              color: isDeliveryInspection ? "#6b7280" : "#000", // Texto cinza
                              opacity: isDeliveryInspection ? 0.8 : 1, // Aparência suavizada
                              border: "1px solid #d1d5db",
                              padding: "4px",
                              borderRadius: "6px",
                              width: "100%", // Responsivo até maxWidth
                              fontSize: "1rem", // Tamanho de fonte pequeno
                            }}
                            value={windscreen}
                            onChange={(e) => setWindscreen(e.target.value)}
                            disabled={isDeliveryInspection}
                          >
                            <option value="0">Yes</option>
                            <option value="1">No</option>
                          </select>

                          {/* Camada de sobreposição no Delivery */}
                          {isDeliveryInspection && (
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.05)", // Transparente
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                pointerEvents: "none", // Bloqueia interação
                                borderRadius: "6px",
                              }}
                            >
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-wrap items-center mb-2">
                        <FaGlassWhiskey className="w-6 h-6 text-red-500" />
                        <p className="ml-4">Intact Glass:</p>
                        {/* <select
                          id="intact_glass"
                          className="ml-2 p-1 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded"
                          value={intactGlass}
                          onChange={(e) => setIntactGlass(e.target.value)}
                          disabled={isDeliveryInspection}
                        >
                          <option value="0">Yes</option>
                          <option value="1">No</option>
                        </select> */}

                        <div style={{ position: "relative", width: "auto", maxWidth: "120px", marginLeft: "0.5rem", }}>
                          <select
                            id="intactGlass"
                            style={{
                              pointerEvents: isDeliveryInspection ? "none" : "auto", // Desativa interação
                              backgroundColor: isDeliveryInspection ? "#e5e7eb" : "#fff", // Fundo cinza claro no Delivery
                              color: isDeliveryInspection ? "#6b7280" : "#000", // Texto cinza
                              opacity: isDeliveryInspection ? 0.8 : 1, // Aparência suavizada
                              border: "1px solid #d1d5db",
                              padding: "4px",
                              borderRadius: "6px",
                              width: "100%", // Responsivo até maxWidth
                              fontSize: "1rem", // Tamanho de fonte pequeno
                            }}
                            value={intactGlass}
                            onChange={(e) => setIntactGlass(e.target.value)}
                            disabled={isDeliveryInspection}
                          >
                            <option value="0">Yes</option>
                            <option value="1">No</option>
                          </select>

                          {/* Camada de sobreposição no Delivery */}
                          {isDeliveryInspection && (
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.05)", // Transparente
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                pointerEvents: "none", // Bloqueia interação
                                borderRadius: "6px",
                              }}
                            >
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-wrap items-center mb-2">
                        <FaFileAlt className="w-6 h-6 text-purple-500" />
                        <p className="ml-4">Paperwork:</p>
                        {/* <select
                          id="paperwork"
                          className="ml-2 p-1 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded"
                          value={paperwork}
                          onChange={(e) => setPaperwork(e.target.value)}
                          disabled={isDeliveryInspection}
                        >
                          <option value="0">Yes</option>
                          <option value="1">No</option>
                        </select> */}

                        <div style={{ position: "relative", width: "auto", maxWidth: "120px", marginLeft: "0.5rem", }}>
                          <select
                            id="paperwork"
                            style={{
                              pointerEvents: isDeliveryInspection ? "none" : "auto", // Desativa interação
                              backgroundColor: isDeliveryInspection ? "#e5e7eb" : "#fff", // Fundo cinza claro no Delivery
                              color: isDeliveryInspection ? "#6b7280" : "#000", // Texto cinza
                              opacity: isDeliveryInspection ? 0.8 : 1, // Aparência suavizada
                              border: "1px solid #d1d5db",
                              padding: "4px",
                              borderRadius: "6px",
                              width: "100%", // Responsivo até maxWidth
                              fontSize: "1rem", // Tamanho de fonte pequeno
                            }}
                            value={paperwork}
                            onChange={(e) => setPaperwork(e.target.value)}
                            disabled={isDeliveryInspection}
                          >
                            <option value="0">Yes</option>
                            <option value="1">No</option>
                          </select>

                          {/* Camada de sobreposição no Delivery */}
                          {isDeliveryInspection && (
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.05)", // Transparente
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                pointerEvents: "none", // Bloqueia interação
                                borderRadius: "6px",
                              }}
                            >
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex-1">
                    <div className="border-b border-gray-200 dark:border-gray-700 pb-2 mb-2">
                      <h2 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
                        <FaBoxOpen className="w-6 h-6 text-orange-500 mr-2" />
                        Loose Items
                      </h2>
                    </div>
                    <div className="bg-gray-50 dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg p-4">
                      <div className="mb-3">
                        <label className="flex items-center">
                          <FaTools className="w-6 h-6 text-orange-500" />
                          <span className="ml-4">Key:</span>
                          {/* <input
                            type="text"
                            className="ml-2 p-0.5 w-1/2 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded h-8"
                            id="keys_qt"
                            placeholder="Enter Number key"
                            value={keysQt}
                            onChange={(e) => setKeysQt(e.target.value)}
                            disabled={isDeliveryInspection}
                          /> */}

                          <div style={{ position: "relative", width: "auto", maxWidth: "120px", marginLeft: "0.5rem", }}>
                            <input
                              id="keys_qt"
                              style={{
                                pointerEvents: isDeliveryInspection ? "none" : "auto", // Desativa interação
                                backgroundColor: isDeliveryInspection ? "#e5e7eb" : "#fff", // Fundo cinza claro no Delivery
                                color: isDeliveryInspection ? "#6b7280" : "#000", // Texto cinza
                                opacity: isDeliveryInspection ? 0.8 : 1, // Aparência suavizada
                                border: "1px solid #d1d5db",
                                padding: "4px",
                                borderRadius: "6px",
                                width: "100%", // Responsivo até maxWidth
                                fontSize: "1rem", // Tamanho de fonte pequeno
                              }}
                              value={keysQt}
                              onChange={(e) => setKeysQt(e.target.value)}
                              disabled={isDeliveryInspection}
                            >
                            </input>

                            {/* Camada de sobreposição no Delivery */}
                            {isDeliveryInspection && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(0, 0, 0, 0.05)", // Transparente
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  pointerEvents: "none", // Bloqueia interação
                                  borderRadius: "6px",
                                }}
                              >
                              </div>
                            )}
                          </div>
                        </label>
                      </div>
                      <div className="mb-3">
                        <label className="flex items-center">
                          <FaCheckSquare className="w-6 h-6 text-green-500" />
                          <span className="ml-4">Spare Tire:</span>
                          <div className="ml-2 flex items-center">
                            <input
                              type="radio"
                              name="spaceTire"
                              value="true"
                              id="spaceTireYes"
                              className="mr-1"
                              checked={spareTire === 'true'}
                              onChange={(e) => setSpareTire(e.target.value)}
                              disabled={isDeliveryInspection}
                            />
                            <label htmlFor="spaceTireYes" className="mr-4">Yes</label>
                            <input
                              type="radio"
                              name="spaceTire"
                              value="false"
                              id="spaceTireNo"
                              className="mr-1"
                              checked={spareTire === 'false'}
                              onChange={(e) => setSpareTire(e.target.value)}
                            />
                            <label htmlFor="spaceTireNo">No</label>
                          </div>
                        </label>
                      </div>

                      <div>
                        <label className="flex items-center">
                          <FaRegQuestionCircle className="w-6 h-6 text-purple-500" />
                          <span className="ml-4">Others:</span>
                          {/* <input
                            type="text"
                            className="ml-2 p-0.5 w-1/2 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded h-8"
                            placeholder="Enter other information"
                            value={others}
                            onChange={(e) => setOthers(e.target.value)}
                          /> */}

                          <div style={{ position: "relative" }}>
                            <input
                              id="others"
                              name="others"
                              required
                              type="text"
                              style={{
                                pointerEvents: isDeliveryInspection ? "none" : "auto", // Desativa interação no Delivery
                                backgroundColor: isDeliveryInspection ? "#e5e7eb" : "#fff", // Fundo cinza claro
                                color: isDeliveryInspection ? "#6b7280" : "#000", // Texto cinza
                                opacity: isDeliveryInspection ? 0.8 : 1, // Suaviza a aparência
                                border: "1px solid #d1d5db",
                                padding: "6px",
                                borderRadius: "6px",
                                width: "100%",
                                marginLeft: "0.75rem",
                              }}
                              placeholder="Enter other information"
                              value={others}
                              onChange={(e) => setOthers(e.target.value)}
                              disabled={isDeliveryInspection}
                            />
                            {isDeliveryInspection && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(0, 0, 0, 0.05)", // Camada transparente
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  pointerEvents: "none", // Impede interação
                                  borderRadius: "8px",
                                  marginLeft: "0.75rem",
                                }}
                              >
                              </div>
                            )}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>

          <Card className="mt-5 ml-2 mr-4">
            <CardBody>
              <div className="mt-1 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
                <div className="flex items-center p-4 border-b border-gray-200 dark:border-gray-700">
                  <FaFileSignature className="w-6 h-6 text-blue-500" style={{ color: 'orange' }} />
                  <h2 className="text-lg font-semibold ml-4 text-gray-900 dark:text-white">Signature Info</h2>
                </div>

                <div className="flex flex-wrap w-full p-4  dark:hover:bg-gray-800 transition-colors duration-200 rounded-lg shadow-lg">
                  <div className="px-2 w-full lg:w-1/3 mb-4 lg:mb-0">
                    <label htmlFor="CustomerName" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                      Customer Name:
                    </label>

                    {/* <input
                      id="CustomerName"
                      name="CustomerName"
                      required
                      type="text"
                      className="block p-2.5 w-full text-sm bg-gray-200 border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      placeholder="Enter Customer Name"
                      value={CustomerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                      disabled={isDeliveryInspection}
                    /> */}

                    <div style={{ position: "relative" }}>
                      <input
                        id="CustomerName"
                        name="CustomerName"
                        required
                        type="text"
                        style={{
                          pointerEvents: "auto", // isDeliveryInspection ? "none" : "auto", // Desativa interação no Delivery
                          backgroundColor: "#fff", // isDeliveryInspection ? "#e5e7eb" : "#fff", // Fundo cinza claro
                          color: "#000", // isDeliveryInspection ? "#6b7280" : "#000", // Texto cinza
                          opacity: 1, // isDeliveryInspection ? 0.8 : 1, // Suaviza a aparência
                          border: "1px solid #d1d5db",
                          padding: "10px",
                          borderRadius: "8px",
                          width: "100%",
                        }}
                        placeholder="Enter Customer Name"
                        value={CustomerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        // disabled={isDeliveryInspection}
                      />
                      {isDeliveryInspection && (
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.05)", // Camada transparente
                            // display: "flex",
                            display: "none",
                            justifyContent: "center",
                            alignItems: "center",
                            pointerEvents: "none", // Impede interação
                            borderRadius: "8px",
                          }}
                        >
                        </div>
                      )}
                    </div>


                  </div>
                  <div className="px-2 w-full lg:w-1/3 mb-4 lg:mb-0">
                    <label htmlFor="CustomerPhone" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                      Customer Phone:
                    </label>
                    {/* <input
                      id="CustomerPhone"
                      name="CustomerPhone"
                      required
                      type="tel"
                      className="block p-2.5 w-full text-sm bg-gray-200 border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      placeholder="123-456-7890"
                    /> */}

                    <div style={{ position: "relative" }}>
                      <input
                        id="CustomerPhone"
                        name="CustomerPhone"
                        required
                        type="text"
                        style={{
                          pointerEvents: "auto", // isDeliveryInspection ? "none" : "auto", // Desativa interação no Delivery
                          backgroundColor: "#fff", // isDeliveryInspection ? "#e5e7eb" : "#fff", // Fundo cinza claro
                          color: "#000", // isDeliveryInspection ? "#6b7280" : "#000", // Texto cinza
                          opacity: 1, // isDeliveryInspection ? 0.8 : 1, // Suaviza a aparência
                          border: "1px solid #d1d5db",
                          padding: "10px",
                          borderRadius: "8px",
                          width: "100%",
                        }}
                        placeholder="123-456-7890"
                        value={CustomerPhone}
                        onChange={(e) => setCustomerPhone(e.target.value)}
                        // disabled={isDeliveryInspection}
                      />
                      {isDeliveryInspection && (
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.05)", // Camada transparente
                            // display: "flex",
                            display: "none",
                            justifyContent: "center",
                            alignItems: "center",
                            pointerEvents: "none", // Impede interação
                            borderRadius: "8px",
                          }}
                        >
                        </div>
                      )}
                    </div>
                  </div>




                  <div className="px-2 w-full lg:w-1/3">
                    <label htmlFor="CustomerEmail" className="text-sm font-medium text-gray-900 dark:text-gray-300 mb-1">
                      Customer Email:
                    </label>
                    {/* <input
                      id="CustomerEmail"
                      name="CustomerEmail"
                      required
                      type="email"
                      className="block p-2.5 w-full text-sm bg-gray-200 border rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      placeholder="name@cajuu.com"
                    /> */}

                    <div style={{ position: "relative" }}>
                      <input
                        id="CustomerEmail"
                        name="CustomerEmail"
                        required
                        type="text"
                        style={{
                          pointerEvents: "auto", // isDeliveryInspection ? "none" : "auto", // Desativa interação no Delivery
                          backgroundColor: "#fff", // isDeliveryInspection ? "#e5e7eb" : "#fff", // Fundo cinza claro
                          color: "#000", // isDeliveryInspection ? "#6b7280" : "#000", // Texto cinza
                          opacity: 1, // isDeliveryInspection ? 0.8 : 1, // Suaviza a aparência
                          border: "1px solid #d1d5db",
                          padding: "10px",
                          borderRadius: "8px",
                          width: "100%",
                        }}
                        placeholder="name@cajuu.com"
                        value={CustomerEmail}
                        onChange={(e) => setCustomerEmail(e.target.value)}
                        // disabled={isDeliveryInspection}
                      />
                      {isDeliveryInspection && (
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.05)", // Camada transparente
                            // display: "flex",
                            display: "none",
                            justifyContent: "center",
                            alignItems: "center",
                            pointerEvents: "none", // Impede interação
                            borderRadius: "8px",
                          }}
                        >
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-2 w-full flex flex-col p-4 text-center -mt-24">
                <SignatureSection ref={signatureRef} />
              </div>
            </CardBody>
          </Card >
        </div>
      )
      }
      <div className="flex justify-center mt-8 mb-4">
        {loading ? (
          <MainSpinner />
        ) : (
          <button
            onClick={handleSubmit}
            className="flex items-center justify-center mt-2 px-4 py-2 bg-orange-500 hover:bg-orange-600 text-white rounded-md"
          >
            <FaCheck className="mr-2" />
            Finish Inspection
          </button>
        )}
      </div>
    </div >
  );
}

export default InspectionLoadItemPage;
