import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Tables from "./pages/Tables";
import Maps from "./pages/Maps";
import Footer from "./components/Footer";
import User from "./pages/User";
import PartnerCompany from "./pages/PartnerCompany";
import Driver from "./pages/Driver";
import Load from "./pages/Load";
import InspectionLoadItem from "./pages/InspectionLoadItem";
import inspection from "../src/pages/Inspection";
import "assets/styles/tailwind.css";
import Login from "./pages/Login";
import PasswordResetForm from "./components/PasswordResetForm";
import ZipcodeMap from "./pages/ZipcodeMap";
import NewQuotes from "./pages/NewQuotes";
import NewLoad from "./pages/NewLoad";
import quotes from "./pages/Quotes";
import PrivateRoute from './components/PrivateRoute';
import DashboardDriver from "pages/DashboardDriver";
import useHomePage from 'hooks/UseHomePage';
import QuoteAcceptance from 'pages/QuoteAcceptance';
// import StaffInvitation from './pages/StaffInvitation';
import QuoteDenial from './pages/QuoteDenial';
import SuperAdmin from './pages/SuperAdmin';
import CarrierRegister from './pages/CarrierRegister';
import SelectOptionsLogin from 'pages/SelectOptionsLogin';
import DriverRegister from 'pages/DriverRegister';
import RegisterUser from './pages/RegisterUser';
import Emailverified from './pages/Emailverified';
import Gallery from './pages/Gallery';
// import StaffInvitationCarrier from './pages/StaffInvitationCarrier';
import StaffManagement from './pages/StaffManagement';
function App() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const id = query.get('id');
    const companyId = query.get('company_id');
    const userRoleId = query.get('user_role');

    if (location.pathname === '/QuoteAcceptance' && id) {
      
      const expirationTime = 2 * 60 * 60 * 1000; // 2 horas em milissegundos
      // const expirationTime = 1 * 60 * 1000; // 1 minuto em milissegundos

      const storedId = localStorage.getItem('quoteId');
      const storedTimestamp = localStorage.getItem('quoteTimestamp');

      if (storedId && storedTimestamp) {
        const storedTimestampNum = parseInt(storedTimestamp, 10);
        const currentTimestamp = Date.now();
        const elapsedTime = currentTimestamp - storedTimestampNum;

        if (elapsedTime > expirationTime) {
          // Tempo expirado, limpar os itens e redirecionar
          localStorage.removeItem('quoteId');
          localStorage.removeItem('quoteTimestamp');
          history.push('/Dashboard'); // Redireciona para a rota /Dashboard
        } else {
          // Tempo não expirado, calcular o tempo restante
          const remainingTime = expirationTime - elapsedTime;

          // Converter milissegundos para minutos e segundos
          const minutes = Math.floor(remainingTime / (1000 * 60));
          const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

          console.log(`ID e Timestamp válidos: ${storedId}, ${storedTimestamp}. Tempo restante: ${minutes} minutos e ${seconds} segundos.`);
        }
      } else {
        // Não há valores armazenados, então armazenamos o novo id e timestamp
        const currentTimestamp = Date.now();
        localStorage.setItem('quoteId', id);
        localStorage.setItem('quoteTimestamp', currentTimestamp.toString());
        console.log('ID e Timestamp armazenados:', id, currentTimestamp);
      }

    } else if (location.pathname === '/Login' && companyId && userRoleId) {
      localStorage.setItem('companyId', companyId);
      localStorage.setItem('userRoleId', userRoleId);
    }
  }, [location]);

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token && !['/Login', '/login', '/QuoteDenial', '/carrier-register', '/SelectOptionsLogin', '/DriverRegister', '/RegisterUser', '/Emailverified', '/Gallery', '/QuoteAcceptance'].includes(location.pathname)) {
      history.push('/Login');
    }
  }, [location, history]);

  const shouldShowSidebarAndFooter = !['/Login', '/login', '/QuoteDenial', '/carrier-register', '/SelectOptionsLogin', '/DriverRegister', '/RegisterUser', '/Emailverified', '/Gallery', '/QuoteAcceptance'].includes(location.pathname) && !location.pathname.startsWith('/reset-password/');

  const homePage = useHomePage();

  return (
    <>
      {shouldShowSidebarAndFooter && <Sidebar />}
      <div className={`md:ml-32 ${shouldShowSidebarAndFooter ? '' : 'md:ml-0'}`}>
        <Switch>
          <PrivateRoute exact path="/Dashboard" component={Dashboard} />
          <PrivateRoute exact path="/DashboardDriver" component={DashboardDriver} />
          <PrivateRoute exact path="/Profile" component={Profile} />
          <PrivateRoute exact path="/tables" component={Tables} />
          <PrivateRoute exact path="/Maps" component={ZipcodeMap} />
          <PrivateRoute exact path="/User" component={User} />
          <PrivateRoute exact path="/PartnerCompany" component={PartnerCompany} />
          <PrivateRoute exact path="/Driver" component={Driver} />
          <Route exact path="/QuoteAcceptance" component={QuoteAcceptance} />
          {/* <Route exact path="/StaffInvitation" component={StaffInvitation} /> */}
          {/* <Route exact path="/StaffInvitationCarrier" component={StaffInvitationCarrier} /> */}
          <Route exact path="/StaffManagement" component={StaffManagement} />
          <Route exact path="/QuoteDenial" component={QuoteDenial} />
          <Route exact path="/Gallery" component={Gallery} />
          <Route exact path="/SuperAdmin" component={SuperAdmin} />
          <Route exact path="/Login" component={Login} />
          <Route exact path="/carrier-register" component={CarrierRegister} />
          <Route exact path="/Emailverified" component={Emailverified} />
          <Route exact path="/SelectOptionsLogin" component={SelectOptionsLogin} />
          <Route exact path="/DriverRegister" component={DriverRegister} />
          <Route exact path="/RegisterUser" component={RegisterUser} />
          <Route path="/reset-password/:token" component={PasswordResetForm} />
          <PrivateRoute path="/Load" component={Load} />
          <PrivateRoute path="/NewLoad" component={NewLoad} />
          <PrivateRoute exact path="/InspectionLoadItem" component={InspectionLoadItem} />
          <PrivateRoute exact path="/Quotes" component={quotes} />
          <PrivateRoute exact path="/NewQuotes" component={NewQuotes} />
          <PrivateRoute exact path="/inspection" component={inspection} />
          <Redirect from="*" to={homePage} />
        </Switch>
        {shouldShowSidebarAndFooter && <Footer />}
      </div>
    </>
  );
}

export default App;
