import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom'; // Notar o uso de useHistory aqui

const PasswordResetForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const { token } = useParams();
    const history = useHistory(); // useHistory ao invés de useNavigate

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setMessage('');

        try {
            const response = await axios.post('https://new.cajuu.app/api/password/reset', {
                email,
                token,
                password,
                password_confirmation: passwordConfirmation,
            });

            setMessage(response.data.message);
            // Redirect to login after a delay to show the success message
            setTimeout(() => history.push('/Login'), 3000); // history.push ao invés de navigate
        } catch (err) {
            if (err.response && err.response.data) {
                let errorMessage = err.response.data.message || 'An error occurred.';
                if (err.response.data.errors) {
                    const detailedErrors = err.response.data.errors;
                    const errorDetails = Object.keys(detailedErrors).map(key =>
                        `${key}: ${detailedErrors[key].join(', ')}`
                    ).join('\n');
                    errorMessage += `\nDetails: ${errorDetails}`;
                }
                setError(errorMessage);
            } else {
                setError('An error occurred in communication with the server.');
            }
        }
    };

    return (
        <div className="p-4">
            <h2 className="text-lg font-bold mb-4">Password Reset</h2>
            {message && <p className="text-green-500">{message}</p>}
            {error && <p className="text-red-500">{error}</p>}
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                    placeholder="Your e-mail"
                    required
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                    placeholder="New password"
                    required
                />
                <input
                    type="password"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                    placeholder="Confirm the new password"
                    required
                />
                <button
                    type="submit"
                    className="w-full p-2 bg-orange-500 text-white rounded hover:bg-orange-700"
                >
                    Reset Password
                </button>
            </form>
        </div>
    );
};

export default PasswordResetForm;
